import { PageHeaderWrapper } from "@clipboard-health/ui-components";
import { type UseModalState } from "@clipboard-health/ui-react";
import { isDefined } from "@clipboard-health/util-ts";
import { DialogContent } from "@mui/material";
import { FullScreenDialog } from "@src/appV2/lib";
import { useCreateOrGetPlacementCandidate } from "@src/appV2/redesign/PlacementCandidate/api/useCreateOrGetPlacementCandidate";
import { PlacementCandidateOnboardingContainer } from "@src/appV2/redesign/PlacementCandidate/components/PlacementCandidateOnboardingContainer";
import { type WorkplaceProfile } from "@src/appV2/redesign/Workplace/types";
import { useDefinedWorker } from "@src/appV2/Worker/useDefinedWorker";

import { PageHeaderTopRow } from "../../components/PageHeaderTopRow";
import { OnboardingSkeleton } from "../../PlacementCandidate/components/PlacementCandidateOnboarding/OnboardingSkeleton";
import { useOnboardingRequirements } from "../../PlacementCandidate/hooks/useOnboardingRequirements";
import { useTrackMarketingLinkClicks } from "../../PlacementCandidate/hooks/useTrackMarketingLinkClicks";
import { WorkplacePlacements } from "./WorkplacePlacements";

interface WorkplaceJobsProps {
  workplaceId: string;
  isLoading?: boolean;
  workplace?: WorkplaceProfile;
  modalState: UseModalState;
}

export function WorkplacePlacementsModal(props: WorkplaceJobsProps) {
  const { workplaceId, isLoading, modalState, workplace } = props;

  const worker = useDefinedWorker();
  const {
    data: placementCandidate,
    isLoading: isPlacementCandidateLoading,
    isSuccess: isPlacementCandidateSuccess,
  } = useCreateOrGetPlacementCandidate(
    { workerId: worker.userId },
    { enabled: isDefined(worker.userId) }
  );
  const { isLoading: isOnboardingRequirementsLoading, isCompleted: isProfileCompleted } =
    useOnboardingRequirements(worker.userId);

  useTrackMarketingLinkClicks({
    placementCandidate,
    workplaceId,
    enabled: isDefined(placementCandidate),
  });

  const isLoadingAnything =
    isOnboardingRequirementsLoading || isPlacementCandidateLoading || isLoading;
  const hasExistingPlacementCandidate = isPlacementCandidateSuccess && isProfileCompleted;

  if (!isLoadingAnything && isPlacementCandidateSuccess && hasExistingPlacementCandidate) {
    return (
      <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
        <WorkplacePlacements
          workplaceId={workplaceId}
          workplace={workplace}
          modalState={modalState}
          placementCandidate={placementCandidate}
        />
      </FullScreenDialog>
    );
  }

  return (
    <FullScreenDialog skipTopSafeAreaPadding modalState={modalState} variant="primary">
      <PageHeaderWrapper variant="primary">
        <PageHeaderTopRow />
      </PageHeaderWrapper>

      <DialogContent>
        {isLoadingAnything && <OnboardingSkeleton />}

        {!isLoadingAnything && isPlacementCandidateSuccess && !hasExistingPlacementCandidate && (
          <PlacementCandidateOnboardingContainer
            worker={worker}
            placementCandidate={placementCandidate}
          />
        )}
      </DialogContent>
    </FullScreenDialog>
  );
}
